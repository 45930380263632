.Footer{
    text-align: center;
    border: 1px solid #efecfd;
    border-radius: 24px 24px 0 0;
    background-color: #fff;
    padding: 8px 0 16px 0;
    margin-top: 10px;
    position:fixed;
    bottom: 0;
    width: 100%;
    z-index: 98;
}
.link_footer{
    display: inline-block;
    position: relative;
    width: 25%;
    text-align: center;
  
}
.link_footer:after{
    content: '';
    position: absolute;
    width: 32px;
    height: 2px;
    background-color:transparent;
    left: calc(50% - 16px);
    bottom: -8px;
}
.link_footer:hover{
    text-decoration: none;
}
.link_footer.active:after,
.link_footer:hover:after{
    background-color: #4c3489;
}
.picto_footer{
    display: block;
    text-align: center;
}
.txt_footer{
    display: block;
    text-align: center;
    color: #4c3489;
    font-size: 9px;
}