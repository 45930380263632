.top_logo {
  background: #4c3489;
  padding: 47px 42px 47px 38px;
  border-radius: 0 0 24px 24px;
  margin-bottom: 40px;
}
.inputs_radio{
  float: left;
}

.box_form_inscri .container_radio {
  font-size: 12px;
  color: #000;
  font-family: 'NHaasGroteskTXPro-65Md', sans-serif;
}
.groupe_radio{
  margin-top:15px;
  float: left;
  width: 100%;
}
.container_radio {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #000;
  font-family: 'NHaasGroteskTXPro-55Rg', sans-serif;
  float: left;
  margin-right: 10px;
  line-height: 24px;
}
.container_radio:last-child {
  margin-right: 0;
}
.container_radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.container_radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid #8b819d;
}

.container_radio input:checked ~ .checkmark {
  border: 1px solid #4c3489;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.container_radio input:checked ~ .checkmark:after {
  display: block;
}
.container_radio .checkmark:after {
  top: 5px;
  left: 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #4c3489;
}
.txt_radio{
  float: left;
  color: #8b819d;
  font-size:12px;
  margin-right:15px;
  font-family: 'NHaasGroteskTXPro-65Md', sans-serif;
}
.inputs_radio{
  float: left;
}

.title_inscri{
  color: #4c3489;
  font-size:24px;
  font-family: 'NHaasGroteskTXPro-75Bd', sans-serif;
  margin-bottom:18px;
  line-height: 1.2;
}

.WrapPaiementResult h3 {
  margin-top: 0; font-family: 'NHaasGroteskTXPro-65Md'; font-size:20px;
}

.WrapPaiementResult .link_next {
  display: inline-block;
  width: 62px;
  height: 62px;
  background: #4c3489;
  border: 2px solid #4c3489;
  text-align: center;
  line-height: 55px;
  border-radius: 50%;
}


.box_next_link {
    text-align: center;
}
.box-paiemnt{ height: 80vh; text-align: center;}
.linkbacks{ margin-top: 25px; display: inline-block; width: 100%;}
.title_gris {
  font-family: "NHaasGroteskTXPro-75Bd";
  margin-top: 28px;
  color:#8b819d;
  font-style: 16px; line-height: 30px; margin-bottom: 15px;
}
.WrapPaiementResult .link_next{ bottom: 0;
  position: relative;
  left: 0;
  right: 0;
  margin:120px auto 56px;    display: block;}

  .formcarte {
    height: 39px;
    padding: 6px 10px 6px 0;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    background-color: transparent;
    background-image: none;
    border: none;
    border-bottom: 1px solid rgba(139, 129, 157,0.5);
    border-radius: 0;
    -webkit-box-shadow: none;
    outline: none;
}
.form-control:focus{  box-shadow:none}
.WrapPaiementResult .title_inscri { margin-bottom:40px ; }
.labercarte{ color:#8b819d; font-family: NHaasGroteskTXPro-55Rg; line-height: 24px; }
