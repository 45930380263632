
.tabs{ position:relative; display:inline-block !important; width:100%; margin-top:0; padding: 16px; margin-bottom: 30px; }
.img_promo{ background: #efecfd; border-radius: 16px;  overflow: hidden; max-width: 100%;
  height: auto;}
.block_promo { margin-bottom:16px;}
.block_promo .titrepro{font-family: NHaasGroteskTXPro-75Bd; font-size: 16px; color:#000000; margin-top: 5px ;margin-bottom: 5px;;
}
.block_promo .col-xs-3 img { max-width:100%; display: inline-block; height:auto;}
.block_promo .parag{
  font-family:NHaasGroteskTXPro-65Md;
  font-size: 12px; margin-bottom: 0;
  color:#786893}
.block_promo div.discount{
  font-family:NHaasGroteskTXPro-65Md;
  font-size: 14px;
  color:#ff595e}
  .tabs .MuiBox-root-10 {
    padding:10px 0;
}

.tabs header .MuiAvatar-root .MuiAvatar-img{ width:30px  !important; height: 30px !important;   }
.tabs header .MuiAvatar-root.MuiAvatar-circle{ width:72px  !important; height: 48px !important; border-radius: 16px;  border:1px solid #f1eef9; background: #faf9ff;  } 
.tabs .MuiAppBar-colorDefault{  background: #faf9ff  !important;}
.tabs .MuiPaper-elevation4 { box-shadow: none  !important;}
.tabs .MuiTab-wrapper {
  color: #4c3489 !important;
  font-size: 10px !important;
  text-transform: none !important;
  font-family: 'NHaasGroteskTXPro-65Md';
  font-weight: normal;  
}

.tabs .PrivateTabIndicator-colorPrimary-2 {
  background-color: #faf9ff !important;
}
.tabs  .MuiTab-textColorPrimary.Mui-selected  .MuiAvatar-root.MuiAvatar-circle{ border:1px solid #68549c }
.MuiTab-textColorPrimary {
  color: rgba(0, 0, 0, 0.54) !important;
  max-width: 72px !important;
  text-align: center;
  margin: 0px 10px !important;
  text-align: center !important;
  padding: 0 !important;
}
.infinite-scroll-component {
  overflow: initial;
}