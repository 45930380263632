.Wrap_Header{
    margin-bottom: 16px;
}
.Header{
    background-color: #4c3489;
    padding: 20px 16px 45px 16px;
    float: left;
    width: 100%;
}
.toggle_icon{
    float: left;
    margin-right: 16px;
    cursor: pointer;
}
.logo_header{
    float: left;
}
.notif_icon{
    float:right;
}
.nav-menu {
    position: fixed;
    top: 0;
    background: #4c3489;
    width: 100%;
    height: 100%;
    z-index: 99999;
    left: -100%;
    transition: 500ms;
    padding: 20px 16px;
}
.nav-menu.active {
    left: 0;
}
.picto_back_navbar{
    float: left;
    cursor: pointer;
}
.box_logo{
    text-align: center;
    margin-top: 36px;
    margin-bottom: 10px;
}
.desc_top_navbar{
    text-align: center;
    max-width: 312px;
    margin: 0 auto;
    font-size: 20px;
    color: #ffffff;
    margin-bottom: 50px;
    font-family: 'NHaasGroteskTXPro-55Rg', sans-serif;
}
.menu_navbar{
    list-style-type: none;
    padding: 0 20px;
    margin: 0;
    font-size: 16px;
    color: #fff;
}
.menu_navbar li{
    margin-bottom: 30px;
}
.link_navbar{
    font-size: 16px;
    color: #fff;
    font-family: 'NHaasGroteskTXPro-75Bd', sans-serif;
}
.link_navbar.active,
.link_navbar:hover {
    font-size: 16px;
    color: #fdce00;
    font-family: 'NHaasGroteskTXPro-75Bd', sans-serif;
    text-decoration: none;
}
.comptename{
    border-bottom: 1px solid rgb(255 255 255 / 25%);
    margin: 0 20px;
    padding: 0 0 15px 0;
    color: #fff;
    margin-bottom: 15px;
}
.comptename a{ color:#fff; font-size: 16px; font-family: 'NHaasGroteskTXPro-55Rg', sans-serif;}

.comptename h3 a{ padding: 0; color:#fff; font-size: 16px;     font-family: 'NHaasGroteskTXPro-75Bd', sans-serif;}