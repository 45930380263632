body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  .filter{ position: absolute;
    width: 100%; height: 64px; display: flex;
    max-width: 100%; z-index: 97; background: 
    #faf9ff; padding: 16px;
    bottom: 0}
    .range-slider__wrap {
      position: relative;
      display: block;
      height: calc(1.5em + 0.75rem + 2px);
      width: 80%;
      top: 4px;
  }
  .Filter-menu{  position: fixed;
      top: 0;
      background: #faf9ff;
      width: 100%;
      height: 100%;
      font-family: "NHaasGroteskTXPro-65Md";
      z-index: 99;
      left: -100%;
      
      transition: 500ms; z-index: 999999;
      padding: 20px 0px;}
  .Filter-menu.active{       left: 0; }
  .textfilter{ width:64px; height: 32px; cursor: pointer;
  font-family:NHaasGroteskTXPro-65Md; line-height: 32px;
  font-size:12px; color:#8b819d;
  text-align:center; border:solid 1px #808b819d; border-radius: 8px;
  max-width:64px;}
  .RangeSlider {
    display: flex;
    max-width: calc(100% - 70px);
    width: 100%;
  }
  .min-km {padding:8px;
    font-size:12px; color:#8b819d;font-family:"NHaasGroteskTXPro-65Md"; }
  
    .enreg{ height: 48px; border-radius: 24px; color:#faf9ff; border: 2px solid #4c3489; width: 100%; color:#4c3489; background: transparent; line-height:46px;}
    .Headerfilter label{ font-family: 'NHaasGroteskTXPro-65Md', sans-serif; font-size: 16px; line-height: 24px;margin-right: 8px;   }
    .Headerfilter li label{ font-weight: 400;font-family: 'NHaasGroteskTXPro-65Md', sans-serif; font-size: 16px; line-height: 24px;margin-right: 8px; color:#000000;   }
    .Headerfilter .label input{margin-right: 8px;   }
    .Headerfilter ul{ padding: 0; }
    .Headerfilter li{ list-style: none; padding: 15px 24px; border-bottom: 1px solid rgba(139, 129, 157, 0.16);}
    .Headerfilter li:last-child{  border-bottom: 0px solid #8b819d;}
  
    .retours{ color:#4c3489; font-size: 20px; font-family: 'NHaasGroteskTXPro-65Md', sans-serif; padding: 19px; height: 24px; margin-bottom: 45px;cursor: pointer; float: left;}
  
    .action_bt{padding: 30px 40px; text-align: center; font-family: 'NHaasGroteskTXPro-65Md'; }
    .Map{
        margin-top: -16px;
        position: relative;
        height: calc(100vh - 175px);
    }
    .container_checkbox {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 0;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      
      /* Hide the browser's default checkbox */
      .container_checkbox input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
      
      /* Create a custom checkbox */
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 24px;
        width: 24px;
        border: 2px solid #4c3489;
        border-radius: 4px;
      }
            
      /* Create the checkmark/indicator (hidden when not checked) */
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }
      .checkmark.error {
        position: absolute;
        top: 0;
        left: 0;
        height: 24px;
        width: 24px;
        border: 2px solid red;
        border-radius: 4px;
      }
            

      /* Show the checkmark when checked */
      .container_checkbox input:checked ~ .checkmark:after {
        display: block;
      }
      
      /* Style the checkmark/indicator */
      .container_checkbox .checkmark:after {
        left: 7px;
    top: 2px;
    width: 7px;
    height: 13px;
    border: solid #4c3489;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
      }

      .resultmap{
        width: calc( 100% - 32px );
        height: 40px;
        top: 0;
        position: absolute;
        background: #fff;
        z-index: 9999;
        color: #4c3489;
        margin: auto;
        left: 0;
        right: 0;
        padding: 8px;
        border-radius: 20px;
        font-family: 'NHaasGroteskTXPro-55Rg';
        text-align: center;
        line-height: 22px;
        margin-top: 16px;
        font-size: 16px;;
      }
      .resultmap strong{        font-family: 'NHaasGroteskTXPro-75Bd';}
      .range-slider__wrap .range-slider__tooltip .range-slider__tooltip__label{ display: none;}

      

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  border: 1px solid #000000 !important;
  height: 32px !important;
  width: 32px !important;
  border-radius: 16px !important;
  background: #ff595e !important;
  cursor: pointer !important;

  margin-top: -14px !important; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  box-shadow: none !important; /* Add cool effects to your sliders! */
  border:8px solid #edc4ca  !important;
}

/* All the same stuff for Firefox */
input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #887a7a !important;
  border: 1px solid #edc4ca !important;
  height: 32px !important;
  width: 32px !important;
  border-radius: 16px !important;
  background: #ff595e !important;
  cursor: pointer !important;
  box-shadow: none !important; /* Add cool effects to your sliders! */
  border:8px solid #edc4ca !important;
}

/* All the same stuff for IE */
input[type=range]::-ms-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 32px !important;
  width: 32px !important;
  border-radius: 16px !important;
  background: #ff595e !important;
  cursor: pointer !important;
  box-shadow: none !important; /* Add cool effects to your sliders! */
  border:8px solid #edc4ca  !important;
}