.WrapTitle{
    background: #faf9ff;
    border-radius: 28px 28px 0 0;
    margin-top: -40px;
    z-index: 9;
    position: relative;
}
.page-content{
    background-color: #fff;
}
.DetailProduct{ margin-bottom: 80px;}