@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap');

@font-face {
  font-family: 'NHaasGroteskTXPro-55Rg';
  src: url('./fonts/NHaasGroteskTXPro-55Rg.eot');
  src: url('./fonts/NHaasGroteskTXPro-55Rg.eot?#iefix') format('embedded-opentype'),
       url('./fonts/NHaasGroteskTXPro-55Rg.woff2') format('woff2'),
       url('./fonts/NHaasGroteskTXPro-55Rg.woff') format('woff'),
       url('./fonts/NHaasGroteskTXPro-55Rg.ttf')  format('truetype'),
       url('./fonts/NHaasGroteskTXPro-55Rg.svg#Neue Haas Grotesk Text Pro') format('svg');
}
@font-face {
  font-family: 'NHaasGroteskTXPro-65Md';
  src: url('./fonts/NHaasGroteskTXPro-65Md.eot');
  src: url('./fonts/NHaasGroteskTXPro-65Md.eot?#iefix') format('embedded-opentype'),
       url('./fonts/NHaasGroteskTXPro-65Md.woff2') format('woff2'),
       url('./fonts/NHaasGroteskTXPro-65Md.woff') format('woff'),
       url('./fonts/NHaasGroteskTXPro-65Md.ttf')  format('truetype'),
       url('./fonts/NHaasGroteskTXPro-65Md.svg#Neue Haas Grotesk Text Pro') format('svg');
}
@font-face {
  font-family: 'NHaasGroteskTXPro-75Bd';
  src: url('./fonts/NHaasGroteskTXPro-75Bd.eot');
  src: url('./fonts/NHaasGroteskTXPro-75Bd.eot?#iefix') format('embedded-opentype'),
       url('./fonts/NHaasGroteskTXPro-75Bd.woff2') format('woff2'),
       url('./fonts/NHaasGroteskTXPro-75Bd.woff') format('woff'),
       url('./fonts/NHaasGroteskTXPro-75Bd.ttf')  format('truetype'),
       url('./fonts/NHaasGroteskTXPro-75Bd.svg#Neue Haas Grotesk Text Pro') format('svg');
}
html,
body,
input,
button {
    font-size: 16px;
    font-weight: 400;
    font-family: 'NHaasGroteskTXPro-65Md', sans-serif;
    color: #070713;
}
html{
  min-height: 100%;
}
body{
  min-height: 100vh;
}
img {
    max-width: 100%;
    height: auto
}
.App{
  background-color: #faf9ff;
  min-height: 100vh;
}

.App-home {
  text-align: center;
  padding: 48px 40px;
  background: #faf9ff;
  min-height: calc(100vh - 80px);
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
}

h2
{font-family: NHaasGroteskTXPro-75Bd;
color:#4c3489;
  font-size: 24px;
  text-align: center;
  line-height: 30px;
}


.App-header {
  background-color: #4c3489 ;
text-align: center;
 padding: 50px 0;
 margin-bottom: 40px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.pageloading{ background: #4c3489; width:100%; height:100vh; position: relative; text-align: center;  }
.pageloading img.logoclub{ top:50%; transform: translate(-50%); position: absolute; left: 50%; margin: auto;  }
.copyright{ font-size:14px ; line-height: 21px; color:#8b819d; font-family: "n_haas_grotesk_tx_pro_55_rg"; bottom:32px;    bottom: 32px;
  position: absolute;
  left: 50%;transform: translateX(-50%);}

  .loadingpng{ font-size:14px ; line-height: 21px; color:#8b819d; font-family: "n_haas_grotesk_tx_pro_55_rg"; bottom:95px;   
    position: absolute;
    left: 50%;transform: translateX(-50%); z-index: 999;}
.AppContent {
    float: left;
    width: 100%;
    background-color: #faf9ff;
    border-radius: 24px 24px 0 0;
    z-index: 999;
    margin-top: -20px;
    padding-top: 15px;
    padding-bottom: 20px;
}
.paddingBottom{
  padding-bottom: 80px;
}