.owl-carousel {
	.owl-item {
		img {
			&.img-slider {
				max-width: 100%;
				height: auto;
				display: inline-block;
			}
		}
	}
}
.slider {
	position: relative;
	img {
		z-index: 99;
		position: relative;
		margin-bottom: -1px;
	}
	
}
.logoprivilege {
	display: inline-block;
	text-align: center;
	margin-top: 22px;
	margin-bottom: 15px;
}
.titre1 {
	font-family: NHaasGroteskTXPro-55Rg;
	font-size: 16px;
	line-height: 22px;
	margin-bottom: 8px;
	color: #fff;
	padding: 0 36px;
}
.titre2 {
	font-family: NHaasGroteskTXPro-75Bd;
	font-size: 24px;
	line-height: 30px;
	color: #fff;
	margin-bottom: 20px;
	padding: 0 36px;
}
.sliderdem {
	text-align: center;
	display: inline-block;
	width: 100%;
	background-color: #4c3489;
}
.WrapDemararage {
	.owl-theme {
		.owl-dots {
			.owl-dot {
				span {
					border: 1px solid #4c3489;
					background: #faf9ff;
				}
			}
		}
	}
	.owl-dots {
		bottom: 155px;
		position: absolute;
		left: 0;
		right: 0;
		margin: auto;
	}
}
.WrapDemararage .owl-theme .owl-dots .owl-dot.active span,
.WrapDemararage .owl-theme .owl-dots .owl-dot:hover span {
	background: #4c3489;
}
.linkBack {
	border: 2px solid #4c3489;
	font-size: 16px;
	border-radius: 24px;
	line-height: 24px;
	text-align: center;
	height: 48px;
	font-family: NHaasGroteskTXPro-75Bd;
	display: inline-block;
	width: 176px;
	padding: 12px;
	color: #4c3489;
	margin-top: 25px;
}
.actionlink {
	display: inline-block;
	text-align: center;
	margin-bottom: 25px;
	width: 100%;
	text-align: center;
}
.link_sonnect {
	display: inline-block;
	text-align: center;
	font-family: NHaasGroteskTXPro-55Rg;
	font-size: 16px;
	line-height: 24px;
	margin-bottom: 25px;
	color: #4c3489;
	margin-bottom: 25px;
}
