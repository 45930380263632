.partner_location_images > img{
    width: 100%;
    height: 248px;
    object-fit: cover;
}
.partner_location_images{
    display: none;
}
.partner_location_images:first-child{
    display: block;
}
.partner_location_images{
    position: relative;
}
.partner_location_images:before{
    content: '';
    position: absolute;
    background:url('./../../assets/images/png/image_cover_effect.png') 0 0;
    height: 96px;
    left: 0;
    top: 0;
    z-index: 99;
    width: 100%;
}
.link_back{
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: 999;
}
.partner_logo{
    position: absolute;
    width: 88px;
    height: 88px;
    bottom: -44px;
    left: calc(50% - 44px);
    z-index: 999;
}
.partner_logo img{
    border-radius: 50%;
}
.box_top_details_product{
    text-align: center;
    margin-bottom: 24px;
}
.partner_category_name{
    font-size: 20px;
    color: #1b1a1c;
}
.partner_name{
    font-size: 12px;
    color: #8b819d;
    font-family: 'NHaasGroteskTXPro-55Rg', sans-serif;
}
.wrap_details_product{
    background-color: #fff;
    padding-top: 60px;
    padding-bottom: 16px;
    text-align: center;
    margin-bottom: 8px;
}
.wrap_accordion{
    background-color: #faf9ff;
}
.MuiAccordion-rounded:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.MuiAccordionSummary-root {
    background: #efecfd !important;
}
.MuiAccordionSummary-root.Mui-expanded{
    background: #efecfd;
    min-height: 48px !important;
}
.MuiAccordionSummary-content.Mui-expanded {
    margin: 12px 0 !important;
}
.MuiCollapse-wrapper,
.MuiCollapse-entered {
    background: #faf9ff;
}
.MuiPaper-elevation1 {
    box-shadow: none !important;
    margin-bottom: 8px; 
}
.MuiAccordion-rounded:last-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.MuiAccordion-root:before {
    background-color: transparent !important;
}
.title_accordion{
    color: #1b1a1c;
    font-size:18px!important;
    font-family: 'NHaasGroteskTXPro-65Md', sans-serif !important;
}
.MuiAccordionDetails-root {
    padding: 16px 8px !important;
}
.promotion_details{
    margin-bottom: 16px;
    float: left;
    width: 100%;
}
.title_promotion{
    color: #4c3489;
    font-size:16px;
    margin-bottom: 5px;
    font-family: 'NHaasGroteskTXPro-75Bd', sans-serif;
}
.desc_promotion{
    color: #000;
    font-size:14px;
    font-family: 'NHaasGroteskTXPro-55Rg', sans-serif;
}
.box_content_promo {
    float: left;
    width: 80%;
}
.link_qr{
    float: right;
    width: 48px;
    height: 50px;
    border:2px solid #fdce00;
    border-radius: 8px;
    background-color: #fff;
    padding: 8px;
}
.read-more-less--more{
    color: #4c3489;
}
.txt_distance{
    color: #4c3489;
    font-size: 12px;
}
.pos_img{
    width: 40px;
    height: 40px;
    display: inline-block;
    background-color: #e8e6f5;
    border-radius: 50%;
    padding: 7px 8px 8px 8px;
    margin-top: 8px;
}
.section_produit_details{
    padding-bottom: 80px;
}