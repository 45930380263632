.ScanWrapper{
    min-height: 100vh;
    background-color: #4c3489;
    padding-top: 20px;
}
.TitleScan{
    color: #faf9ff;
    font-size: 20px;
    font-family: 'NHaasGroteskTXPro-75Bd', sans-serif;
}
.boxScan{
padding-top: 15px;
}
.TitleScan{
    margin-bottom: 25px;
}
.boxImgScan{
    margin-bottom: 24px;
}
.BeforeAfterScan{
    position: relative;
    display: inline-block;
    padding-top: 30px;
    padding-bottom: 30px;
    width: 282px;
    text-align: center;
}
.BeforeAfterScan:before{
    content: '';
    position: absolute;
    background:url('./../../assets/images/jpg/before_scan.jpg') 0 0;
    height: 25px;
    width: 282px;
    left: 0;
    top: 0;
    z-index: 99;
}
.linkBack{ border:2px solid #4c3489; font-size: 16px; border-radius: 24px; line-height: 24px; text-align: center; height: 48px; font-family: NHaasGroteskTXPro-75Bd; display: block; width:176px; padding: 12px; color:#4c3489; margin-bottom: 25px;  }


.BeforeAfterScan:after{
    content: '';
    position: absolute;
    background:url('./../../assets/images/jpg/after_scan.jpg') 0 0;
    height: 25px;
    width: 282px;
    left: 0;
    bottom: 0;
    z-index: 99;
}
.btnScan{
    width: 104px;
    height: 104px;
    border-radius:28px ;
    text-align: center;
    border:none;
    outline: none;
    background:url('./../../assets/images/jpg/btn_scan.jpg') center center #fdce00 no-repeat;
}
.boxCamScan > section{
    width: 280px;
    height: 304px;
    margin: 0 auto;
    padding-top: 30px;
    padding-bottom: 30px;
}
.boxCamScan > section > section  > div {
    box-sizing: border-box;
    border: none !important;
    box-shadow: none !important;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 28px;
}