.owl-carousel-category .owl-stage {
    margin-left: -40px;
}
.logo_partenaire{
    position: absolute;
    top:8px;
    left: 8px;
    z-index: 999;
}
.parag{
    font-family:NHaasGroteskTXPro-65Md;
    font-size: 10px; margin-bottom: 0;
    color:#786893
}

.box_item_cartridges .img_partner_location{
    display: none;
}
.box_item_cartridges .img_partner_location:first-child{
    display: block;
}
.title_cartridges{
    color: #1b1a1c;
    font-size: 20px;
    margin-bottom: 16px;
}
.owl-carousel-category .owl-item{
    padding-bottom: 50px;
}
.owl-carousel-category .owl-item .item_carrousel {
    border-radius: 24px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    box-shadow: 0px 15px 13px 0 rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0px 15px 13px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 15px 13px 0 rgba(0, 0, 0, 0.15);
}

.title_result_appart{
    display: block;
    width:100%;
}
.box_item_cartridges{
    float: left;
    width: 50%;
    border-radius: 24px 0 0 24px;
    background-color: #efecfd;
}
.box_content_cartridges{
    float: left;
    width: 50%;
    border-radius:  0 24px 24px 0;
    background-color: #fff;
    padding: 16px;
    height: 100%;
}
.vertical_slide .box_content_cartridges{
    float: left;
    width: 100%;
    border-radius: 0 0 24px 24px;
    height: 50%;
    padding: 8px 16px;
}
.vertical_slide .box_item_cartridges{
    float: left;
    width: 100%;
    border-radius: 24px 24px 0 0;
    height: 50%;
}
.vertical_slide .img_partner_location img{
    border-radius: 24px 24px 0 0;
}
.vertical_slide .owl-carousel-category .owl-stage {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;    margin-left: -16px;

}
.vertical_slide .owl-carousel-category .owl-item .item_carrousel{
height: 100%;
}
.logo_partenaire img,
.logo_partenaire{
    width: 48px;
    height: 48px;
    border-radius: 50%;
}
.img_partner_location img{
    height: 160px;
    width: 100%;
    object-fit: cover;
    border-radius: 24px 0 0 24px;
}
.title_partner{
    color: #000000;
    font-size: 16px;
    font-family: 'NHaasGroteskTXPro-65Md', sans-serif;
}
.category_partner{
    color: #8b819d;
    font-size: 11px;
    font-family: 'NHaasGroteskTXPro-55Rg', sans-serif;
}
.distance_partner{
    color: #786893;
    font-size: 12px;
}
.discount_partner{
    color: #ff595e;
    font-size: 12px;
}
.WrapCartridges {
    background: #faf9ff;
    border-radius: 28px 28px 0 0;
    margin-top: -40px;
    z-index: 9;
    position: relative;
}