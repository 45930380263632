.list_app{
  width:100%;
  background: rgba(139, 129, 157, 0.1);
  padding:1px;
  border-radius:10px;
  float: left;
  margin-top:16px;
  list-style: none;
  margin-bottom: 16px;
}
.list_app li{
  float: left;
  width:50%;
  text-align: center;
}
.list_app li a{
  font-family: 'NHaasGroteskTXPro-65Md', sans-serif;
  font-size:14px;
  color: #8b819d;
  float: left;
  width: 100%;
  padding:8px 10px;
}

.list_app li.active a{
  background: #fff;
  color: #4c3489;
  border-radius:10px;
}
.list_app li a:hover{
  text-decoration: none;
}
.headtab{color:#8b819d; font-size: 14px;margin: 8px 0; font-family: NHaasGroteskTXPro-55Rg; }
.bodytab{color:#000000; background: #fff; padding: 8px 0; font-family: NHaasGroteskTXPro-55Rg; margin: 8px 0; border-radius: 8px; font-size: 14px;
  -webkit-box-shadow: 0px 1px 0px 0px rgba(232,228,243,1);
  -moz-box-shadow: 0px 1px 0px 0px rgba(232,228,243,1);
  box-shadow: 0px 1px 0px 0px rgba(232,228,243,1);
}
.WrapHistorique {
  border-radius: 32px;
  top: -45px;
  position: relative;
  padding-top: 6px;
  background: #faf9ff;
  margin-top: 10px;
}