                                                                                                        .container_inscri{
                                                                                                            padding: 4px 25px 32px 25px;
                                                                                                        }
                                                                                                        .top_inscri{
                                                                                                            background: #4c3489;
                                                                                                            padding:10px 42px 27px 28px;
                                                                                                            border-radius: 0 0 24px 24px;
                                                                                                            margin-bottom:20px;
                                                                                                        }
                                                                                                        .title_inscri{
                                                                                                            color: #4c3489;
                                                                                                            font-size:24px;
                                                                                                            font-family: 'NHaasGroteskTXPro-75Bd', sans-serif;
                                                                                                            margin-bottom:18px;
                                                                                                            line-height: 1.2;
                                                                                                        }
                                                                                                        .form-club{
                                                                                                            height: 39px;
                                                                                                            padding: 6px 10px 6px 0;
                                                                                                            font-size: 14px;
                                                                                                            color: #555;
                                                                                                            background-color: transparent;
                                                                                                            background-image: none;
                                                                                                            border: none;
                                                                                                            border-bottom: 1px solid rgba(139, 129, 157,0.5);
                                                                                                            border-radius: 0;
                                                                                                            -webkit-box-shadow: none;
                                                                                                        }
                                                                                                        .box_form_inscri label{
                                                                                                            color: #4c3489;
                                                                                                            font-size:12px;
                                                                                                            font-family: 'NHaasGroteskTXPro-65Md', sans-serif;
                                                                                                            font-weight:400;
                                                                                                        }
                                                                                                        .box_form_inscri ::-webkit-input-placeholder { /* Edge */
                                                                                                            color: #8b819d;
                                                                                                            font-size:18px;
                                                                                                            font-family: 'NHaasGroteskTXPro-55Rg', sans-serif;
                                                                                                        }

                                                                                                        .box_form_inscri :-ms-input-placeholder { /* Internet Explorer 10-11 */
                                                                                                            color: #8b819d;
                                                                                                            font-size:18px;
                                                                                                            font-family: 'NHaasGroteskTXPro-55Rg', sans-serif;
                                                                                                        }

                                                                                                        .box_form_inscri ::placeholder {
                                                                                                            color: #8b819d;
                                                                                                            font-size:18px;
                                                                                                            font-family: 'NHaasGroteskTXPro-55Rg', sans-serif;
                                                                                                        }
                                                                                                        .box_form_inscri input:focus,
                                                                                                        .form-club:focus,
                                                                                                        .form-control:focus {
                                                                                                            border-color: #4c3489;
                                                                                                            box-shadow: none;
                                                                                                            border-bottom-width: 2px;
                                                                                                        }
                                                                                                        .box_form_inscri .container_radio {
                                                                                                            font-size: 12px;
                                                                                                            color: #000;
                                                                                                            font-family: 'NHaasGroteskTXPro-65Md', sans-serif;
                                                                                                        }
                                                                                                        .groupe_radio{
                                                                                                            margin-top:15px;
                                                                                                            float: left;
                                                                                                            width: 100%;
                                                                                                        }
                                                                                                        .container_radio {
                                                                                                            display: block;
                                                                                                            position: relative;
                                                                                                            padding-left: 30px;
                                                                                                            margin-bottom: 12px;
                                                                                                            cursor: pointer;
                                                                                                            font-size: 14px;
                                                                                                            -webkit-user-select: none;
                                                                                                            -moz-user-select: none;
                                                                                                            -ms-user-select: none;
                                                                                                            user-select: none;
                                                                                                            color: #000;
                                                                                                            font-family: 'NHaasGroteskTXPro-55Rg', sans-serif;
                                                                                                            float: left;
                                                                                                            margin-right: 10px;
                                                                                                            line-height: 24px;
                                                                                                        }
                                                                                                        .container_radio:last-child {
                                                                                                            margin-right: 0;
                                                                                                        }
                                                                                                        .container_radio input {
                                                                                                            position: absolute;
                                                                                                            opacity: 0;
                                                                                                            cursor: pointer;
                                                                                                        }
                                                                                                        .container_radio .checkmark {
                                                                                                            position: absolute;
                                                                                                            top: 0;
                                                                                                            left: 0;
                                                                                                            height: 24px;
                                                                                                            width: 24px;
                                                                                                            background-color: transparent;
                                                                                                            border-radius: 50%;
                                                                                                            border: 1px solid #8b819d;
                                                                                                        }

                                                                                                        .container_radio input:checked ~ .checkmark {
                                                                                                            border: 1px solid #4c3489;
                                                                                                        }
                                                                                                        .checkmark:after {
                                                                                                            content: "";
                                                                                                            position: absolute;
                                                                                                            display: none;
                                                                                                        }
                                                                                                        .container_radio input:checked ~ .checkmark:after {
                                                                                                            display: block;
                                                                                                        }
                                                                                                        .container_radio .checkmark:after {
                                                                                                            top: 5px;
                                                                                                            left: 5px;
                                                                                                            width: 12px;
                                                                                                            height: 12px;
                                                                                                            border-radius: 50%;
                                                                                                            background: #4c3489;
                                                                                                        }
                                                                                                        .txt_radio{
                                                                                                            float: left;
                                                                                                            color: #8b819d;
                                                                                                            font-size:12px;
                                                                                                            margin-right:15px;
                                                                                                            font-family: 'NHaasGroteskTXPro-65Md', sans-serif;
                                                                                                        }
                                                                                                        .inputs_radio{
                                                                                                            float: left;
                                                                                                        }

                                                                                                        .box_abonnement{
                                                                                                            background: #fff;
                                                                                                            margin-top:16px;
                                                                                                            padding:8px 8px 16px 8px;
                                                                                                            border-radius:16px;
                                                                                                            margin-bottom:17px;
                                                                                                        }
                                                                                                        .box_abonnement .container_checkbox{
                                                                                                            font-size:16px;
                                                                                                            color: #4c3489;
                                                                                                            font-weight:400;
                                                                                                            font-family: 'NHaasGroteskTXPro-75Bd', sans-serif;
                                                                                                        }
                                                                                                        .desc_abonnement{
                                                                                                            color: #8b819d;
                                                                                                            font-size:14px;
                                                                                                            font-family: 'NHaasGroteskTXPro-55Rg', sans-serif;
                                                                                                            margin-top: 12px;
                                                                                                        }
                                                                                                        .btn_club{
                                                                                                            border:2px solid #4c3489;
                                                                                                            padding:11px 45px;
                                                                                                            color: #4c3489;
                                                                                                            font-size:16px;
                                                                                                            border-radius:24px;
                                                                                                            font-family: 'NHaasGroteskTXPro-75Bd', sans-serif;
                                                                                                            outline:none;
                                                                                                            margin-bottom: 20px;
                                                                                                        }
                                                                                                        .btn_club:hover{
                                                                                                            border:2px solid #4c3489;
                                                                                                            background: #4c3489;
                                                                                                            color: #fff;
                                                                                                        }
                                                                                                        .box_form_code{
                                                                                                            margin-top:10px;
                                                                                                            float: left;
                                                                                                            width:100%;
                                                                                                            text-align: center;
                                                                                                        }
                                                                                                        .box-for-code{
                                                                                                            display: inline-block;
                                                                                                        }
                                                                                                        .form-code-club{
                                                                                                            display: inline-block;
                                                                                                            width:56px;
                                                                                                            height:72px;
                                                                                                            margin:0 4px;
                                                                                                            border:2px solid #d8d4e1;
                                                                                                            border-radius:8px;
                                                                                                            background: #faf9ff;
                                                                                                            text-align: center;
                                                                                                            color: #000000;
                                                                                                            font-size:32px;
                                                                                                            font-family: 'NHaasGroteskTXPro-55Rg', sans-serif;
                                                                                                        }
                                                                                                        .form-code-club.error{
                                                                                                            border:2px solid #ff595e;
                                                                                                        }
                                                                                                        input[type=number]::-webkit-outer-spin-button,
                                                                                                        input[type=number]::-webkit-inner-spin-button {
                                                                                                            -webkit-appearance: none;
                                                                                                            margin: 0;
                                                                                                        }
                                                                                                        input[type=number] {
                                                                                                            -moz-appearance: textfield;
                                                                                                        }
                                                                                                        .txt_error{
                                                                                                            color: #ff595e;
                                                                                                            font-size:12px;
                                                                                                            text-align: center;
                                                                                                            margin-top:8px;
                                                                                                        }
                                                                                                        .box_next_link{
                                                                                                            text-align: center;
                                                                                                        }
                                                                                                        .link_next{
                                                                                                            display: inline-block;
                                                                                                            width:62px;
                                                                                                            height:62px;
                                                                                                            background: #4c3489;
                                                                                                            border:2px solid #4c3489;
                                                                                                            text-align: center;
                                                                                                            line-height:55px;
                                                                                                            border-radius: 50%;

                                                                                                        }
                                                                                                        .marg-top-80{
                                                                                                            margin-top:80px;
                                                                                                        }
                                                                                                        .marg-top-55{
                                                                                                            margin-top:55px;
                                                                                                        }
                                                                                                        .standard_link{
                                                                                                            color:#4c3489;
                                                                                                            font-size:16px;
                                                                                                            font-family: 'NHaasGroteskTXPro-55Rg', sans-serif;
                                                                                                            display: inline-block;
                                                                                                        }
                                                                                                        .standard_link:hover{
                                                                                                            color:#fdce00;
                                                                                                            text-decoration: none;
                                                                                                        }
                                                                                                        .scanned_wrapper {
                                                                                                            padding-top: 30px;
                                                                                                        }
                                                                                                        .box-scanned {
                                                                                                            padding-top: 50px;
                                                                                                        }
                                                                                                        .category_scan{
                                                                                                            color: #020102;
                                                                                                            font-size:16px;
                                                                                                            margin-bottom:8px;
                                                                                                            font-family: 'NHaasGroteskTXPro-55Rg', sans-serif;
                                                                                                        }
                                                                                                        .promo_scan{
                                                                                                            color: #4c3489;
                                                                                                            font-size:24px;
                                                                                                            margin-bottom:32px;
                                                                                                            font-family: 'NHaasGroteskTXPro-75Bd', sans-serif;
                                                                                                        }
                                                                                                        .box_img_scanned{
                                                                                                            margin-bottom:32px;
                                                                                                            overflow: hidden;
                                                                                                        }
                                                                                                        .before_after_scanned {
                                                                                                            position: relative;
                                                                                                            display: inline-block;
                                                                                                            padding-top: 30px;
                                                                                                            padding-bottom: 30px;
                                                                                                            width: 254px;
                                                                                                            text-align: center;
                                                                                                        }
                                                                                                        .before_after_scanned:before {
                                                                                                            content: '';
                                                                                                            position: absolute;
                                                                                                            // background: url('../images/jpg/before_scanned.jpg') no-repeat 0 0;
                                                                                                            height: 23px;
                                                                                                            width: 254px;
                                                                                                            left: 0;
                                                                                                            top: 0;
                                                                                                            z-index: 9;
                                                                                                        }
                                                                                                        .before_after_scanned:after {
                                                                                                            content: '';
                                                                                                            position: absolute;
                                                                                                            // background: url('../images/jpg/after_scanned.jpg') no-repeat 0 0;
                                                                                                            height: 23px;
                                                                                                            width: 2542px;
                                                                                                            left: 0;
                                                                                                            bottom: 0;
                                                                                                            z-index: 9;
                                                                                                        }
                                                                                                        .id_scanned {
                                                                                                            margin-top: -19px;
                                                                                                            z-index: 10;
                                                                                                            position: relative;
                                                                                                        }
                                                                                                        .txt_code_partner{
                                                                                                            color: #4c3489;
                                                                                                            font-size:20px;
                                                                                                            margin-bottom:32px;
                                                                                                            font-family: 'NHaasGroteskTXPro-75Bd', sans-serif;
                                                                                                        }
                                                                                                        .txt_condition_qr{
                                                                                                            color: #8b819d;
                                                                                                            font-size:12px;
                                                                                                            font-family: 'NHaasGroteskTXPro-55Rg', sans-serif;
                                                                                                        }
                                                                                                        .header_html{
                                                                                                            background: #4c3489;
                                                                                                            padding: 47px 42px 47px 38px;
                                                                                                        }
                                                                                                        .WrapCartridges {
                                                                                                            background: #faf9ff;
                                                                                                            border-radius: 28px 28px 0 0;
                                                                                                            margin-top: -40px;
                                                                                                            z-index: 9;
                                                                                                            position: relative;
                                                                                                            margin-bottom: 80px;
                                                                                                        }
                                                                                                        .list_app{
                                                                                                            width:100%;
                                                                                                            background: rgba(139, 129, 157, 0.1);
                                                                                                            padding:1px;
                                                                                                            border-radius:10px;
                                                                                                            float: left;
                                                                                                            margin-top:16px;
                                                                                                        }
                                                                                                        .list_app li{
                                                                                                            float: left;
                                                                                                            width:50%;
                                                                                                            text-align: center;
                                                                                                        }
                                                                                                        .list_app li a{
                                                                                                            font-family: 'NHaasGroteskTXPro-65Md', sans-serif;
                                                                                                            font-size:14px;
                                                                                                            color: #8b819d;
                                                                                                            float: left;
                                                                                                            width: 100%;
                                                                                                            padding:8px 10px;
                                                                                                        }

                                                                                                        .list_app li.active a{
                                                                                                            background: #fff;
                                                                                                            color: #4c3489;
                                                                                                            border-radius:10px;
                                                                                                        }
                                                                                                        .list_app li a:hover{
                                                                                                            text-decoration: none;
                                                                                                        }
                                                                                                        .wrap_profile{
                                                                                                            margin-top:24px;
                                                                                                            text-align: center;
                                                                                                            margin-bottom: 8px;
                                                                                                        }
                                                                                                        .img_profile{
                                                                                                            margin-bottom:16px;
                                                                                                        }
                                                                                                        .img_profile img{
                                                                                                            width:168px;
                                                                                                        }
                                                                                                        .title_profile{
                                                                                                            color: #1b1a1c;
                                                                                                            font-size:20px;
                                                                                                            font-family: 'NHaasGroteskTXPro-65Md', sans-serif;
                                                                                                        }
                                                                                                        .sexe_profile{
                                                                                                            font-family: 'NHaasGroteskTXPro-55Rg', sans-serif;
                                                                                                            color: #8b819d;
                                                                                                            font-size:14px;
                                                                                                        }
                                                                                                        .tel_profile a{
                                                                                                            color: #4c3489;
                                                                                                            font-size:14px;
                                                                                                            margin-bottom:16px;
                                                                                                            display: inline-block;
                                                                                                            font-family: 'NHaasGroteskTXPro-65Md', sans-serif;
                                                                                                        }
                                                                                                        .edit_profile{
                                                                                                            width:40px;
                                                                                                            height:40px;
                                                                                                            margin:0 auto;
                                                                                                            border:none;
                                                                                                            outline:none;
                                                                                                            background: #e8e6f5;
                                                                                                            border-radius:50%;
                                                                                                        }
                                                                                                        .btn_expired {
                                                                                                            border: 2px solid #ff595e;
                                                                                                            background: #ff595e;
                                                                                                            padding: 11px 24px;
                                                                                                            color: #fff;
                                                                                                            font-size: 14px;
                                                                                                            border-radius: 8px;
                                                                                                            font-family: 'NHaasGroteskTXPro-65Md', sans-serif;
                                                                                                            outline: none;
                                                                                                            width:100%;
                                                                                                        }
                                                                                                        .btn_expired:hover {
                                                                                                            background: transparent;
                                                                                                            color: #ff595e;
                                                                                                        }
                                                                                                        .phone-error {
                                                                                                            float: left;
                                                                                                            color: #e80e0e;
                                                                                                            width:100%; 
                                                                                                            text-align: center;
                                                                                                        }
                                                                                                        .name-error {
                                                                                                            float: left;
                                                                                                            color: #e80e0e;
                                                                                                            width:100%; 
                                                                                                            text-align: left;
                                                                                                        }
                                                                                                        .checkmark-error {
                                                                                                            float: left;
                                                                                                            color: #e80e0e;
                                                                                                            width:100%; 
                                                                                                            text-align: left;
                                                                                                        }
                                                                                                        .link_next {
                                                                                                            outline: none;
                                                                                                        }